.mock-login-screen-overlay {
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9001;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.mock-login-screen {
  width: 500px;
  height: 600px;
}

.mock-login-screen .list-group-item {
  cursor: pointer;
}
