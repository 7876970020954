main {
  margin-top: 72px;
}

body {
  background: linear-gradient(90deg, #3a0cf3 0, #0000 170px), #070329;
  color: white;
  font-family: avenir;
}

a {
  color: white;
  transition: color 0.15s;
}

a:hover {
  color: #5163f9;
}

.main-footer {
  padding: 5px 10px;
  text-align: center;
}

.main-footer,
.main-footer a,
.main-footer a:hover {
  color: #C4B9F9;
}

@font-face {
  font-family: "avenir";
  src: local("Avenir Next, Regular"),
    url("./assets/fonts/avenir/Metropolis-Regular.otf");
}

@font-face {
  font-family: "avenir";
  font-weight: bold;
  src: local("Avenir Next, Bold"),
    url("./assets/fonts/avenir/Metropolis-Bold.otf");
}

@font-face {
  font-family: "druk";
  src: local("Druk Wide Medium"),
    url("./assets/fonts/druk/Druk Wide Medium.ttf");
}

@font-face {
  font-family: "druk";
  font-weight: bold;
  src: local("Druk Wide Bold"), url("./assets/fonts/druk/Druk Wide Bold.ttf");
}
