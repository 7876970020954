
.inventory-header {
  display: block;
  width: 100%;
  height: 254px;
  background: #131736;
  border-radius: 28px;
  margin-bottom: 30px;
}

.inventory-header-top {
  position: relative;
  background-image: url(../../assets/inventory-header-background.png);
  border-radius: 28px 28px 0 0;
  height: 127px;
  background-position-x: center;
  background-repeat: no-repeat;
}

.inventory-header-top p {
  font-family: Druk;
  font-style: normal;
  font-weight: bold;
  font-size: 33.53px;
  line-height: 44px;
  text-transform: uppercase;

  padding-left: 60px;
}

.inventory-header-bottom {
  margin-left: 40px;
  height: 127px;
}

.inventory-header-bottom .btn {
  font-family: Avenir;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;

  min-width: 135px;
  border-radius: 7px;
  padding: 10px 20px;
  margin-right: 10px;
  box-shadow: none;
}

.inventory-header-bottom .btn-secondary {
  background-color: transparent;
  border-color: transparent;
  color: #606692;
}

.inventory-header-bottom .btn-secondary.current {
  background-color: #6028ff;
  border-color: #6028ff;
  color: #ffffff;
}

.inventory-header-bottom .edit-profile {
  color: #b2b8e4;
}
